import React, {useEffect, useState} from "react";
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css";
import "../style/gallery.css"

function getImages(num, fileYear, naming) {
    const imageCount = num; // Number of images in the directory
    const images = Array.from({ length: imageCount }, (_, index) => ({
        original: `${process.env.PUBLIC_URL}/images/${fileYear}/${naming}${index + 1}.png`,
        // thumbnail: `${process.env.PUBLIC_URL}/images/MDM2024/WomenHealth${index + 1}.png`,
    }));
    return images;
}

// Test Images \\
const images = [
    {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
];
// IMAGE SETS | Arrays of URLs
const images2024 = getImages(54, "MDM2024", "WomenHealth");
const images2023 = getImages(27, "MDM2023", "Heart");
const images2022 = getImages(15, "MDM2022", "GI");
const images2021 = getImages(5, "MDM2021", "TBI");
const images2020 = getImages(19, "MDM2020", "Hemorrhage");
const images2019 = getImages(8, "MDM2019", "COPD");

// Gallery Display \\
const Gallery = () => {
    return (
        <>
            <div className="previous-year" id="y2024">
                <div className="year-info">
                    <h1>2024</h1>
                    <h2>Obstetrics | Gynecology | Cancer</h2>
                    <p>
                        At least more than half of the population suffers from Women's Health issues,
                        yet this remains as the least explored medical field.
                    </p>
                    <p id="host"> Host: University of Texas at San Antonio </p>
                </div>
                <ImageGallery items={images2024} />
            </div>
            <div className="previous-year" id="y2023">
                <div className="year-info">
                    <h1>2023</h1>
                    <h2>Heart Failure | Cardiac Arrhythmias Structural/Congenital Defects</h2>
                    <p>
                        About 697,000 people die of heart disease in the United States every year
                        - that's 1 in every 5 deaths.
                    </p>
                    <p id="host"> Host: Texas A&M University </p>
                </div>
                <ImageGallery items = {images2023} />
            </div>
            <div className="previous-year" id="y2022">
                <div className="year-info">
                    <h1>2022</h1>
                    <h2>GI Organ Failure</h2>
                    <p>
                        Overall healthcare expenditure for the gastrointestinal disease is around $136 billion annually
                        which is more than heart disease and trauma expenditure.
                    </p>
                    <p id="host"> Host: University of Texas at San Antonio </p>
                </div>
                <ImageGallery items = {images2022} />
            </div>
            <div className="previous-year" id="y2021">
                <div className="year-info">
                    <h1>2021</h1>
                    <h2>Traumatic Brain Injury</h2>
                    <p>
                        Traumatic Brain Injury often occurs as a result of a severe sports injury or car accident.
                        Over 50 million people have been affected by a traumatic brain injury worldwide.
                    </p>
                    <p id="host">Hosts:<br/>University of Texas at San Antonio<br/>& University of North Texas </p>
                </div>
                <ImageGallery items = {images2021} />
            </div>

            <div className="previous-year" id="y2020">
                <div className="year-info">
                    <h1>2020</h1>
                    <h2>Hemorrhage</h2>
                    <p>
                        Hemorrhaging, whether internal or external, is catastrophic when left untreated.
                        While the causes of bleeding injuries vary as much as the patients who receive them,
                        it’s undeniable that thousands of people suffer from them each year.
                    </p>
                    <p id="host"> Host: University of Texas at San Antonio </p>
                </div>
                <ImageGallery items = {images2020} />
            </div>

            <div className="previous-year" id="y2019">
                <div className="year-info">
                    <h1>2019</h1>
                    <h2>COPD</h2>
                    <p id="smaller-fit">
                        Chronic Obstructive Pulmonary Disease (COPD) refers to a group of diseases that cause airflow
                        blockage and breathing-related problems. It includes emphysema, chronic bronchitis, and in some
                        cases, asthma. Many patients have more than one of these conditions concurrently.
                    </p>
                    <p id="host"> Host: University of Texas at San Antonio </p>
                </div>
                <ImageGallery items = {images2019} />
            </div>

        </>
    );
}

export default Gallery;