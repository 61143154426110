import Header from "../components/header/header";
import ContactUs from "../components/email/contact-email";
import ContactForm from "../components/email/contact-us-email";
import HeaderImage from "../images/headers/contact-us.png";
import React from "react";
import '../App.css';
import '../style/contact.css';
import Logo from "../images/MDM-Logo.png";


const Contact = () => {
    return (
        <>
            <header>
                <Header text="Contact Us" tag="contact" backgroundImage={HeaderImage}/>
            </header>
            <main>
                <hr/>
                <div className="contain-form-faq">
                    {/*<div className="form-faq"><ContactUs/></div>*/}
                    <div className="form-faq">
                        <h2>Private Message</h2>
                        <ContactForm/>
                        <p><br/>or email us directly at oumdm2025@ou.edu</p>
                    </div>
                    <div className="form-faq">
                        <h2>GroupMe</h2>
                        <h1>MDM Announcements</h1>
                        <img src={require("../images/QR-Announcements.png")} alt={"GroupMe Announcements"} loading="lazy"/>
                        <h1>MDM Student Chat</h1>
                        <img src={require("../images/QR-Students.png")} alt={"GroupMe Student Chat"} loading="lazy"/>
                    </div>
                    {/*<div className="form-faq"><ContactForm/></div>*/}
                </div>
                <h1>Frequently Asked Questions</h1>
                <div className="faq">


                </div>

            </main>
        </>
    );
}
export default Contact;