const Info = () => {
    return (
        <>
            <br/><br/><br/><br/><br/><br/>
            <br/><br/><br/><br/><br/><br/>
            <h2>Please check back soon as we update event specific information!</h2>
        </>
    );
}

export default Info;