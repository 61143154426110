import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import "./email.css"

const ContactForm = () => {
    const form = useRef(); // Form reference
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const [disabled, setDisabled] = useState(false);
    const [alertInfo, setAlertInfo] = useState({
        display: false,
        message: '',
        type: '',
    });

    // Shows alert message for form submission feedback
    const toggleAlert = (message, type) => {
        setAlertInfo({ display: true, message, type });

        // Hide alert after 5 seconds
        setTimeout(() => {
            setAlertInfo({ display: false, message: '', type: '' });
        }, 5000);
    };

    // Function called on submit - Uses email.js to send email
    const onSubmit = async (data) => {
        const { name, affiliate, email, subject, message } = data;
        try {
            // Disable form while processing submission
            setDisabled(true);

            // Must match in email.js template
            const templateParams = {
                from_name: name,
                from_affiliate: affiliate,
                from_email: email,
                subject: subject,
                message: message,
            };

            await emailjs.send(
                import.meta.env.service_hbnwkec,    // Service ID   (Email Services)
                import.meta.env.template_0hcmdqq,   // Template ID  (Email Templates)
                // templateParams,
                import.meta.env.JBd6GsheRHNkYwcr5,  // Public Key   (Account)
            );

            toggleAlert('Form submission was successful!', 'success');
        } catch (e) {
            console.error(e);
            toggleAlert('Uh oh. Something went wrong.', 'danger');
        } finally {
            // Re-enable form & reset
            setDisabled(false);
            reset();
        }
    };

    return (
        <div className='ContactForm'>
            <form ref={form} onSubmit={handleSubmit(onSubmit)} noValidate>
                {/* Row 1 of form */}
                <div className='formRow'>
                    <div className='form-entry'>
                    <input type='text' name='name'
                           {...register('name', {
                               required: 'Please enter your name',
                               maxLength: {
                                   value: 30,
                                   message: 'Please use 30 characters or less',
                               },
                           })} className='form-control formInput' placeholder='Name'
                    />
                    {errors.name && (<span className='errorMessage'>{errors.name.message}</span>)}
                    </div>

                    <div className="form-entry">
                    <input
                        type='email'
                        name='email'
                        {...register('email', {
                            required: 'Please enter your email',
                            pattern: {
                                value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: 'Please enter a valid email address',
                            },
                        })}
                        className='form-control formInput'
                        placeholder='Email'
                    />
                    {errors.email && (
                        <span className='errorMessage'>{errors.email.message}</span>
                    )}
                </div>
                </div>

                {/* Row 2 of form */}
                <div className='row formRow'>
                    <div className="form-entry">
                        <input type='text' name='from_affiliate'
                               {...register('from_affiliate', {
                                   required: 'Please enter your affiliation',
                               })}
                               className='form-control formInput'
                               placeholder='University or Company'
                        />
                        {errors.from_affiliate && (
                            <span className='errorMessage'>
                        {errors.from_affiliate.message}
                      </span>
                        )}
                    </div>
                    <div className='form-entry'>
                        <input
                            type='text'
                            name='subject'
                            {...register('subject', {
                                required: 'Please enter a subject',
                                maxLength: {
                                    value: 75,
                                    message: 'Subject cannot exceed 75 characters',
                                },
                            })}
                            className='form-control formInput'
                            placeholder='Subject'
                        />
                        {errors.subject && (
                            <span className='errorMessage'>
                        {errors.subject.message}
                      </span>
                        )}
                    </div>
                </div>

                {/* Row 3 of form */}
                <div className='row formRow'>
                    {/*<div className="message">*/}
                    <textarea
                        rows={5}
                        name='message'
                        {...register('message', {
                            required: 'Please enter a message',
                        })}
                        className='form-control formInput'
                        placeholder='Message'
                    ></textarea>
                        {errors.message && (
                            <span className='errorMessage'>{errors.message.message}</span>
                        )}
                    {/*</div>*/}
                </div>

                <button
                    className='submit-btn btn btn-primary'
                    disabled={disabled}
                    type='submit'
                >
                    Submit
                </button>
            </form>
            {alertInfo.display && (
                <div
                    className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
                    role='alert'
                >
                    {alertInfo.message}
                    <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='alert'
                        aria-label='Close'
                        onClick={() =>
                            setAlertInfo({ display: false, message: '', type: '' })
                        }
                    ></button>
                </div>
            )}
        </div>
    );
};

export default ContactForm;
