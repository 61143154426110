import Header from "../components/header/header";
import HeaderImage from "../images/headers/circuit-art.png";
import React from "react";
import "../style/register.css"

const Register = () => {
    return (
        <>
            {/*<header className="registering">*/}
                {/*<span>Register for</span>*/}
                {/*<h1> MDM 2025</h1>*/}
                {/*<Header text="MDM 2025" tag="register" backgroundImage={HeaderImage}/>*/}

                {/*<hr/>*/}
            {/*</header>*/}
            {/*<main>*/}
            {/*    <div className="pages-container">*/}
            {/*        <div>*/}

                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfs4E8IjUiBhEhn-l_EPpb2YvoxWRhdsXsaQBOCFTexrtUqiw/viewform?embedded=true"
                                width="1740" height="1962" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                    {/*</div>*/}
                    {/*<div className="side-rules">*/}
                        {/*<div className="dates">*/}
                        {/*    Event Dates:*/}
                        {/*</div>*/}
                        {/*<div className="price">*/}
                        {/*    Pricing:*/}
                        {/*</div>*/}
                        {/*<div className="what-you-need">*/}
                        {/*    <h1>Requirements:</h1>*/}
                        {/*    All competitors must be current undergraduate students*/}
                        {/*    Competitors must be registered on a TEAM of FOUR to FIVE MEMBERS before the registration deadline, FEB 14, 2025.*/}
                        {/*    All Team Members must be registered under the same team name or they will be DISQUALIFIED.*/}
                        {/*    At least two team members must be freshmen or sophomores. Team members DO NOT have to be from the same university.*/}
                        {/*</div>*/}
                        {/*<img src={require("../images/reg-schedule.png")} alt={"Natasha Tarwirei"} loading={"lazy"}/>*/}
                    {/*</div>*/}
                {/**/}
                {/*</div>*/}



            {/*</main>*/}
        </>
    );
}

export default Register;


