import React from "react";
import './header.css';

const Header = ({text, text2, backgroundImage, tag}) => {

    return (
        <header className="header" id={tag} style={{backgroundImage: `url(${backgroundImage})`}}>
            <h1>{text}<br/><span className="next-line">{text2}</span></h1>
        </header>
    );
};

export default Header;
